export const FETCH_NEWS = 'app/News/FETCH_NEWS'

export const FETCH_NEWS_SLUG = 'app/News/FETCH_NEWS_SLUG'

export const FETCH_NEWS_SLUG_SUCCESS = 'app/News/FETCH_NEWS_SLUG_SUCCESS'

export const FETCH_NEWS_SLUG_FAIL = 'app/News/FETCH_NEWS_SLUG_FAIL'

export const FETCH_NEWS_SUCCESS = 'app/News/FETCH_SUCCESS'

export const FETCH_NEWS_FAIL = 'app/News/FETCH_FAIL'
