import { append, assoc, concat, merge } from 'ramda'
import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import {
  FETCH_MERCH,
  FETCH_MERCH_FAIL,
  FETCH_MERCH_SLUG_SUCCESS,
  FETCH_MERCH_SUCCESS,
} from './constants'
import { IMerch } from './types'

interface IInitialState {
  loading: boolean
  moreLoading: boolean
  loaded: boolean
  merch: IMerch[]
  pagination: Pagination
}

export const initialState: IInitialState = {
  loading: false,
  moreLoading: false,
  loaded: false,
  merch: [],
  pagination: {
    limit: 3,
    next: 1,
    page: 0,
  },
}

export type MerchAction = ActionType<typeof actions>

function merchReducer(state = initialState, action: MerchAction) {
  switch (action.type) {
    case FETCH_MERCH:
      if (state.merch.length === 0) {
        return assoc('loading', true, state)
      }

      return assoc('moreLoading', true, state)
    case FETCH_MERCH_SUCCESS:
      return merge(state, {
        loading: false,
        moreLoading: false,
        loaded: true,
        merch: concat(state.merch, action.payload.merch),
        pagination: action.payload.pagination,
      })
    case FETCH_MERCH_FAIL:
      return merge(state, {
        loading: false,
        loaded: false,
      })
    case FETCH_MERCH_SLUG_SUCCESS:
      return assoc('merch', append(action.payload)(state.merch), state)
    default:
      return state
  }
}

export default merchReducer
