import React, { lazy, FC, Suspense, useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { Route, Switch, withRouter } from 'react-router-dom'
import LoadingIndicator from 'src/components/LoadingIndicator'
import PrivateRoute from './PrivateRoute'

const LoginPage = lazy(() => import('src/pages/Login'))
const LogoutPage = lazy(() => import('src/pages/Logout'))
const HomePage = lazy(() => import('src/pages/Home'))
const NewsDetailPage = lazy(() => import('src/pages/NewsDetail'))
// const SpeakersDetailPage = lazy(() => import('src/pages/SpeakersDetail'))
const NotFoundPage = lazy(() => import('src/pages/NotFound'))
const MerchDetailPage = lazy(() => import('src/pages/MerchDetail'))

// eslint-disable-next-line no-shadow
const Routes: FC<RouteComponentProps> = React.memo(({ location }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
    window.ga('send', 'pageview', location.pathname)
  }, [location])

  return (
    <Suspense fallback={<LoadingIndicator />}>
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/logout" component={LogoutPage} />
        <PrivateRoute exact path="/" component={HomePage} />
        <PrivateRoute exact path="/news/:slug" component={NewsDetailPage} />
        <PrivateRoute exact path="/merch/:slug" component={MerchDetailPage} />
        {/* <PrivateRoute exact path="/speakers" component={SpeakersDetailPage} /> */}
        <Route component={NotFoundPage} />
      </Switch>
    </Suspense>
  )
})

/* tslint:disable */
declare global {
  interface Window {
    ga: Function
  }
}
/* tslint:enable */

export default withRouter(Routes)
