import { assoc, merge } from 'ramda'
import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import {
  FETCH_PROJECTS,
  FETCH_PROJECTS_FAIL,
  FETCH_PROJECTS_SUCCESS,
} from './constants'

export const initialState = {
  loading: false,
  loaded: false,
  projects: [],
}

export type ProjectsAction = ActionType<typeof actions>

function projectsReducer(state = initialState, action: ProjectsAction) {
  switch (action.type) {
    case FETCH_PROJECTS:
      return assoc('loading', true, state)
    case FETCH_PROJECTS_SUCCESS:
      return merge(state, {
        loading: false,
        loaded: true,
        projects: action.payload,
      })
    case FETCH_PROJECTS_FAIL:
      return merge(state, {
        loading: false,
        loaded: false,
      })
    default:
      return state
  }
}

export default projectsReducer
