export const FETCH_MERCH = 'app/Merch/FETCH_MERCH'

export const FETCH_MERCH_SLUG = 'app/Merch/FETCH_MERCH_SLUG'

export const FETCH_MERCH_SLUG_SUCCESS = 'app/Merch/FETCH_MERCH_SLUG_SUCCESS'

export const FETCH_MERCH_SLUG_FAIL = 'app/Merch/FETCH_MERCH_SLUG_FAIL'

export const FETCH_MERCH_SUCCESS = 'app/Merch/FETCH_SUCCESS'

export const FETCH_MERCH_FAIL = 'app/Merch/FETCH_FAIL'
