/* global gapi */
import React, { useEffect } from 'react'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'
import { login, logout } from 'src/data/app/actions'
import 'src/global/fonts.css'
import Routes from 'src/routes'
import { useUser } from './data/app/selectors'
import { useAppStore } from './data/app/store'
import { CalendarProvider } from './data/calendar/store'
import { ProjectsProvider } from './data/forecast/store'
import { gapiInitializeSuccess } from './data/gapi/actions'
import { useGapiInitialized } from './data/gapi/selectors'
import { useGapiStore } from './data/gapi/store'
import { NewsProvider } from './data/news/store'
import GlobalStyles from './global/styles'
import { MerchProvider } from './data/merch/store'
import { authorize } from './utils/auth'

export const browserHistory = createBrowserHistory()

const App = () => {
  const [, dispatchApp] = useAppStore()
  const [, dispatchGapi] = useGapiStore()
  const user = useUser()
  const gapiInitialized = useGapiInitialized()

  useEffect(() => {
    const storedUserData = localStorage.getItem('user')

    if (storedUserData && !user) {
      const parsedUserData = JSON.parse(storedUserData)

      authorize(parsedUserData.accessToken)
        .then(() => dispatchApp(login(parsedUserData)))
        .catch(() => dispatchApp(logout()))
    }
  }, [dispatchApp, user])

  useEffect(() => {
    if (user && gapiInitialized)
      gapi.client.setToken({
        access_token: user.accessToken,
      })
  }, [user, gapiInitialized])

  useEffect(() => {
    gapi.load('client', () => {
      gapi.client
        .init({})
        .then(() => gapi.client.load('calendar', 'v3'))
        .then(() => dispatchGapi(gapiInitializeSuccess()))
    })
  }, [dispatchApp, dispatchGapi])

  return (
    <MerchProvider>
      <NewsProvider>
        <CalendarProvider>
          <ProjectsProvider>
            <GlobalStyles />
            <Router history={browserHistory}>
              <Routes />
            </Router>
          </ProjectsProvider>
        </CalendarProvider>
      </NewsProvider>
    </MerchProvider>
  )
}

export default App
