import { assoc } from 'ramda'
import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import { GAPI_INITIALIZE_SUCCESS } from './constants'

export const initialState = {
  gapiInitialized: false,
}

export type GapiAction = ActionType<typeof actions>

function gapiReducer(state = initialState, action: GapiAction) {
  switch (action.type) {
    case GAPI_INITIALIZE_SUCCESS:
      return assoc('gapiInitialized', true, state)
    default:
      return state
  }
}

export default gapiReducer
