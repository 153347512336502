import { assoc, merge } from 'ramda'
import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import {
  FETCH_EVENTS,
  FETCH_EVENTS_FAIL,
  FETCH_EVENTS_SUCCESS,
} from './constants'

export const initialState = {
  loading: false,
  loaded: false,
  events: [],
  // pagination: null,
}

export type CalendarAction = ActionType<typeof actions>

function calendarReducer(state = initialState, action: CalendarAction) {
  switch (action.type) {
    case FETCH_EVENTS:
      return assoc('loading', true, state)
    case FETCH_EVENTS_SUCCESS:
      return merge(state, {
        loading: false,
        loaded: true,
        events: action.payload,
        // pagination: action.payload.meta.pagination,
      })
    case FETCH_EVENTS_FAIL:
      return merge(state, {
        loading: false,
        loaded: false,
      })
    default:
      return state
  }
}

export default calendarReducer
