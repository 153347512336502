import nodeFetch from 'node-fetch'
import { google } from '../config.json'

export async function authorize(accessToken: string) {
  const payload = await getUserInfo(accessToken)
  const user = {
    email: payload?.email,
    name: payload?.name,
    accessToken: accessToken,
  }

  if (!(payload?.hd && isDomainAllowed(payload.hd)))
    throw new Error('Access is not allowed.')

  return { user }
}

function isDomainAllowed(domain: string) {
  const allowedDomains = google.domain

  return allowedDomains.includes(domain)
}

async function getUserInfo(accessToken: string) {
  const response = await nodeFetch(
    `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${accessToken}`
  )

  const body: {
    email?: string
    name?: string
    hd?: string
  } = await response.json()

  return body
}
