import { assoc, merge } from 'ramda'
import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import { LOGIN, LOGOUT } from './constants'

export const initialState = {
  user: null,
}

export type AppAction = ActionType<typeof actions>

function appReducer(state = initialState, action: AppAction) {
  switch (action.type) {
    case LOGOUT:
      return assoc('user', null, state)
    case LOGIN:
      return merge(state, {
        user: action.payload,
      })
    default:
      return state
  }
}

export default appReducer
