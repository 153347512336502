/* global gapi */
import React, { FC } from 'react'
import { RouteProps } from 'react-router'
import { Redirect, Route } from 'react-router-dom'
import LoadingIndicator from 'src/components/LoadingIndicator'
import { useUser } from 'src/data/app/selectors'
import { useGapiInitialized } from 'src/data/gapi/selectors'

const PrivateRoute: FC<RouteProps> = (props) => {
  const user = useUser()
  const gapiInitialized = useGapiInitialized()

  if (!gapiInitialized) {
    return <LoadingIndicator />
  }

  if (!user) {
    const pathname = props.location?.pathname
    if (pathname !== '/') {
      return <Redirect to={`/logout?ref=${pathname}`} />
    }
    return <Redirect to="/logout" />
  }

  return <Route {...props} />
}

export default PrivateRoute
