import theme from 'src/global/theme'
import styled, { keyframes } from 'styled-components'

const loaderAnimation = keyframes`
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }

  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
`

export const Wrapper = styled.div`
  margin: 2em auto;
  width: 40px;

  height: 200px;
  position: relative;
  overflow: hidden;
`

export const Loader = styled.div`
  background: ${theme.colors.primary};
  animation: ${loaderAnimation} 1s infinite ease-in-out;
  animation-delay: -0.16s;
  width: 1em;
  height: 4em;
  color: ${theme.colors.primary};
  text-indent: -9999em;
  margin: 88px auto;
  position: relative;
  font-size: 11px;
  transform: translateZ(0);

  &::before,
  &::after {
    position: absolute;
    top: 0;
    content: '';
    background: ${theme.colors.primary};
    animation: ${loaderAnimation} 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
  }

  &::before {
    left: -1.5em;
    animation-delay: -0.32s;
  }

  &::after {
    left: 1.5rem;
  }
`
