import React from 'react'
import ReactDOM from 'react-dom'
import 'sanitize.css'
import App from './App'
import { AppProvider } from './data/app/store'
import { GapiProvider } from './data/gapi/store'

const MOUNT_NODE = document.getElementById('root')!

const render = () =>
  // eslint-disable-next-line react/no-render-return-value
  ReactDOM.render(
    <AppProvider>
      <GapiProvider>
        <App />
      </GapiProvider>
    </AppProvider>,
    MOUNT_NODE
  )

if (module.hot) {
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./routes'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE)
    render()
  })
}

render()
