import React, {
  createContext,
  FC,
  useContext,
  useMemo,
  useReducer,
} from 'react'

type createStoreType = (reducer: any, initialState: any) => [FC, () => any]

export const createStore: createStoreType = (reducer, initialState) => {
  const context = createContext<any>(undefined)

  const Provider: FC = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    const contextValue = useMemo(() => [state, dispatch], [state])

    return <context.Provider value={contextValue}>{children}</context.Provider>
  }

  const useStore = () => useContext(context)

  return [Provider, useStore]
}
