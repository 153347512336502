import React from 'react'
import { Loader, Wrapper } from './styled'

const LoadingIndicator = () => (
  <Wrapper>
    <Loader />
  </Wrapper>
)

export default LoadingIndicator
