const theme = {
  colors: {
    primary: '#ef0d33',
    black: '#111417',
    white: '#fff',
    grey: '#888888',
    greyMedium: '#cecfcf',
    greyLight: '#f5f5f5',
    greyBg: '#f5f5f5',
  },
  fonts: {
    primary: 'Maison Neue, sans-serif',
    secondary: 'Playfair Display, sans-serif',
    headline: 'Trump Gothic East, sans-serif',
  },
  transition: 'all 0.25s ease',
}

export default theme
