import { append, assoc, concat, merge } from 'ramda'
import { ActionType } from 'typesafe-actions'
import * as actions from './actions'
import {
  FETCH_NEWS,
  FETCH_NEWS_FAIL,
  FETCH_NEWS_SLUG_SUCCESS,
  FETCH_NEWS_SUCCESS,
} from './constants'
import { INews } from './types'

interface IInitialState {
  loading: boolean
  moreLoading: boolean
  loaded: boolean
  news: INews[]
  pagination: Pagination
}

export const initialState: IInitialState = {
  loading: false,
  moreLoading: false,
  loaded: false,
  news: [],
  pagination: {
    limit: 10,
    next: 1,
    page: 0,
  },
}

export type NewsAction = ActionType<typeof actions>

function newsReducer(state = initialState, action: NewsAction) {
  switch (action.type) {
    case FETCH_NEWS:
      if (state.news.length === 0) {
        return assoc('loading', true, state)
      }

      return assoc('moreLoading', true, state)
    case FETCH_NEWS_SUCCESS:
      return merge(state, {
        loading: false,
        moreLoading: false,
        loaded: true,
        news: concat(state.news, action.payload.news),
        pagination: action.payload.pagination,
      })
    case FETCH_NEWS_FAIL:
      return merge(state, {
        loading: false,
        loaded: false,
      })
    case FETCH_NEWS_SLUG_SUCCESS:
      return assoc('news', append(action.payload)(state.news), state)
    default:
      return state
  }
}

export default newsReducer
