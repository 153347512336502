/* stylelint-disable property-no-vendor-prefix */

import { createGlobalStyle } from 'styled-components'
import theme from './theme'

export default createGlobalStyle`
  html,
  body {
    font-size: 62.5%;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    -webkit-print-color-adjust: exact;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  @page {
    size: A4;
    margin: 0;
  }

  body {
    overflow-x: hidden;
    background: ${theme.colors.greyLight};
    color: ${theme.colors.black};
    font-family: ${theme.fonts.primary};

    --webkit-text-size-adjust: 100%;
    --ms-text-size-adjust: 100%;
    --webkit-font-smoothing: antialiased;

    @media print {
      transform: scale(0.8);
      transform-origin: 0 0;
      width: 125%;
    }
  }

  #app {
    min-height: 100%;
    min-width: 100%;
  }
`
